<template lang="html">
  <b-card class="sheet-card-vue max-w-lg">
    <div class="jaya-top-row">
      <viewer :images="getPhoto(sheet)" class="jaya-container">
        <img v-for="src in getPhoto(sheet)" :key="src" :alt="'Fiche pratique ' + sheet.title" :src="src" class="jaya-image">
      </viewer>
    </div>

    <h2 class="text-center mt-1">{{ sheet.title }}</h2>

    <div class="text-justify font-medium-2 mb-1 font-weight-bold">
      <p v-for="(part,id) in sheet.introduction.split('\n')" :key="id" class="mt-1">{{part}}</p>
    </div>

    <TextBlock
        v-for="paragraph in sheetParagraphs"
        :key="paragraph.order"
        :title="paragraph.title"
        activeColor="var(--secondary)">
      <div class="text-justify">
        <span v-for="(item, index) in paragraph.content" :key="index">
        <span v-if="item.type==='text'">
          <span v-for="(part,id) in parseTextParagraph(item.content.text)" :key="id">
            {{part[0]}}
            <br v-if="id>0 && id<part[1]-1">
            <br v-if="id>0 && id<part[1]-1">
          </span>
        </span>
        <JayaTooltip
            v-if="item.type==='tip'"
            :text="item.content.description">
          {{ item.content.expression }}
        </JayaTooltip>
        <b-list-group v-if="item.type==='list'" flush>
          <b-list-group-item
              v-for="listItem in item.content.items"
              :key="listItem.order"
              class="d-flex align-items-center">
            {{ listItem.text }}
          </b-list-group-item>
          </b-list-group>
        <span v-if="item.type==='link'" class="pr-1">
            <span class="text-primary cursor-pointer" @click="openLink(item.content.url)"> {{
                item.content.expression
              }}</span>
        </span>
      </span>
      </div>
    </TextBlock>

    <div v-if="sheet.local_authority_infos" class="d-flex justify-content-start align-items-center mt-3">
      <img v-if="sheet.local_authority_infos.photo_url" :alt="sheet.local_authority_infos.name + ' logo'" :src="getImage(sheet.local_authority_infos.photo_url)" class="mr-1" style="width: 30px;">
      <span>Fiche proposée par<strong> {{ sheet.local_authority_infos.name }} </strong></span>
    </div>
  </b-card>
</template>

<script>
import {BButton, BCard, BListGroup, BListGroupItem} from "bootstrap-vue";
import TextBlock from '@/components/global/JayaTextBlock.vue'
import _ from 'lodash'

export default {
  name: 'sheet-card',
  components: {
    BButton,
    BListGroup,
    BListGroupItem,
    BCard,
    TextBlock,
  },
  props: {
    sheet: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      sheetParagraphs: [],
    }
  },
  mounted() {
    for (const paragraph of this.sheet.paragraphs) {
      var paragraphItems = {order: paragraph.order, title: paragraph.title, content: []}
      for (const text of paragraph.texts) {
        paragraphItems.content.push({
          type: 'text',
          order: text.order,
          content: text
        })
      }
      for (const list of paragraph.lists) {
        paragraphItems.content.push({
          type: 'list',
          order: list.order,
          content: list
        })
      }
      for (const tip of paragraph.tips) {
        paragraphItems.content.push({
          type: 'tip',
          order: tip.order,
          content: tip
        })
      }
      for (const link of paragraph.links) {
        paragraphItems.content.push({
          type: 'link',
          order: link.order,
          content: link
        })
      }
      paragraphItems.content = _.orderBy(paragraphItems.content, 'order')
      this.sheetParagraphs.push(paragraphItems)
    }
    this.sheetParagraphs = _.orderBy(this.sheetParagraphs, 'order')
  },
  methods: {
    getImage(photo_url) {
      return process.env.VUE_APP_BACKEND_URL + '/..' + photo_url + "&size=600"
    },
    getPhoto() {
      if (this.sheet.photos && this.sheet.photos.length > 0) {
        return [process.env.VUE_APP_BACKEND_URL + '/..' + this.sheet.photos[0].photo_url + "&size=600"]
      }
      return [require('@/assets/images/default_pictures/help_sheet_default.svg')]
    },
    openLink(url) {
      if (process.env.VUE_APP_USE_TOKEN) {
        // Force using external browser
        window.cordova.InAppBrowser.open(url, '_system')
      } else {
        //Open new tab
        let openUrl = url
        // Check if url starts with http, otherwise the url is stacked to ours
        if (!/^http/.test(url)) {
          openUrl = "http://" + openUrl
        }
        window.open(openUrl, '_blank')
      }
    },
    parseTextParagraph(text) {
      const parsedText = text.split('\n')
      const parsedTextLength = new Array(parsedText.length)
      for (let i = 0; i < parsedText.length; i++) {
        parsedTextLength[i] = [parsedText[i], parsedText.length]
      }
      return parsedTextLength
    },
  }
}
</script>

<style lang="scss">
.sheet-card-vue {
  .image {
    transform: translateY(1.25rem);
    object-fit: contain;
    max-height: 50vh;
  }

  h2 {
    font-size: 3rem;
  }
}
</style>
